import React, {Fragment} from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import Cta from '../components/cta'
import SolutionText from '../components/solutionText'
import FormTraining from '../components/formTraining'

const Partners = ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src }
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}             
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <div
                className={`font-w-400 text-xs-extra-large divlink ${post.html ? 'text-opening-para' : 'text-opening-para-mb0'}`}
                dangerouslySetInnerHTML={{
                  __html: post.frontmatter.openingpara,
                }}
              />
              <div
                className="mt-3 richtext divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>         

      {post.frontmatter.section && 
    <>
      {post.frontmatter.section.map((sections, index) => (
        <Fragment key={index}>
          {sections.type === 'text' && (
            <>            
            <SolutionText data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
            </>
          )}          
        </Fragment>
      ))}
      </>
    }      

        {location.pathname === "/partners/training/" &&         
        <section className="bg-white">
                   
         <FormTraining />
        </section>
        }

      {post.frontmatter.cta && (
        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      )}
    </Layout>
  )
}
export default Partners

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "partners" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        name
        title
        subtitle
        ctatext
        ctaslug         
        openingpara
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        alttext
        section {
          title
          subtitle
          intro
          description
          class
          type
          link
          linktext
          anchorid
          placement
          document {
            publicURL
          }
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          featurecols
        }             
        cta {
          title
          description
          linktext
          link
        }
      }
    }
  }
`
